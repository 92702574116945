@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
}
html {
  font-size: 16px;
}
body {
  font-family: "Urbanist", sans-serif;
}

.login-bg {
  background-image: url("../assets/loginbg.gif");
  background-size: cover;
  /* Cover the entire viewport */
  background-position: center;
  /* Center the background image */
  background-repeat: no-repeat;
  /* Do not repeat the background image */
  background-color: black;
  height: 100vh;
}

.gradient-box {
  background: radial-gradient(
        circle at 100% 100%,
        black 0,
        black 30px,
        transparent 30px
      )
      0% 0%/32px 32px no-repeat,
    radial-gradient(circle at 0 100%, black 0, black 30px, transparent 30px)
      100% 0%/32px 32px no-repeat,
    radial-gradient(circle at 100% 0, black 0, black 30px, transparent 30px) 0%
      100%/32px 32px no-repeat,
    radial-gradient(circle at 0 0, black 0, black 30px, transparent 30px) 100%
      100%/32px 32px no-repeat,
    linear-gradient(black, black) 50% 50% / calc(100% - 4px) calc(100% - 64px)
      no-repeat,
    linear-gradient(black, black) 50% 50% / calc(100% - 64px) calc(100% - 4px)
      no-repeat,
    linear-gradient(268deg, #ff7d05 0%, transparent 70%),
    radial-gradient(at 100% 100%, #e02424 0%, transparent 70%),
    radial-gradient(at 100% 0%, #3354f4 0%, transparent 70%),
    radial-gradient(at 0% 0%, #01fff4 0%, transparent 70%), #6552fe;
  border-radius: 2rem;
  box-sizing: border-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1.875rem black inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #f3f3f3 !important;
}
.btn-primary-gradient {
  /* background: conic-gradient(from 0deg at 74.93% -6%, #6552FE 161.39362335205078deg, #01FFF4 360deg); */
  background: conic-gradient(
    from 0deg at 74.93% -30%,
    #6552fe 230.393623deg,
    #01fff4 360deg
  );
}
.otp-input-conatiner > div {
  justify-content: center;
}

@media (max-width: 1399px) {
  html {
    font-size: 14px;
  }
}
.checkbox {
  display: inline-flex;
  position: relative;
}

.checkbox > input {
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid white;
  border-radius: 2px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #41b88300;
  cursor: pointer;
}
.checkbox > input:checked {
  border: 1px solid #6552fe;
  background: conic-gradient(
    from 0deg at 74.93% -30%,
    #6552fe 230.393623deg,
    #01fff4 360deg
  );
}

.checkbox > input:checked::before {
  content: url("/src/assets/tick.svg");
  display: block;
  text-align: center;
  color: white;
  position: absolute;
  left: 0.1px;
  top: -2px;
  width: 17px; /* Adjust the size of the default tick */
  height: 17px; /* Adjust the size of the default tick */
}
.MuiList-root {
  background: #4b4b4b;
}
#scrollId::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

#scrollId::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

#scrollId::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 30px;
  background: #353535;
}

#scrollId::-webkit-scrollbar-thumb:hover {
  background: #353535;
}

.border-user-btn {
  border: 2px solid
    linear-gradient(
      180deg,
      #6552fe -23.85%,
      #01fff4 56.55%,
      rgba(30, 30, 30, 0.63) 100%
    );
}
.gradient-border {
  position: relative;
  padding: 10px; /* Adjust padding as needed */
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    #6552fe -23.85%,
    #01fff4 56.55%,
    rgba(30, 30, 30, 0.63) 100%
  );
  border-radius: inherit;
}

.border-wrap {
  /* max-width: 250px; */
  padding: 6px;
  position: relative;
  background: linear-gradient(#6552fe, #01fff4, #1e1e1e);
  padding: 2.5px;
}
.module {
  background: #0b0a0a;
}
